import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const UUTestReport = makeShortcode("UUTestReport");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Bruk av kort`}</h2>
    <p>{`Kortene skal hjelpe brukerne med å navigere seg til riktig side. De skal gi en kort og tydelig oppsummering av hva slags informasjon som finnes på undersiden.`}</p>
    <h2>{`CardBase`}</h2>
    <p>{`Dette er basisen for alle kort. Komponeten kan brukes direkte for å få en ramme du kan fylle
dersom du trenger et spesialtilpassert kort. `}</p>
    <p>{`Du kan skru av/på box-shadow med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`shadow`}</code>{`-prop, skru av margin med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`noMargin`}</code>{`-prop, og sette bakgrunnsfargen i både light- og darkmode med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bgColor`}</code>{`og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bgDarkmodeColor`}</code>{`.`}</p>
    <DSExample name="cards_CardBase" mdxType="DSExample" />
    <UUTestReport reportID="Cards_CardBase" mdxType="UUTestReport" />
    <h2>{`TextCard`}</h2>
    <p>{`Tekstkort er den enkleste korttypen. Den tilbyr alle underkomponenter (CardName, Title, Subtext, Text og CardAction)
og plasserer dem i gitt rekkefølge nedover i kortet, med teksten midtstilt.`}</p>
    <DSExample name="cards_TextCard" mdxType="DSExample" />
    <UUTestReport reportID="Cards_TextCard" mdxType="UUTestReport" />
    <h2>{`IconCard`}</h2>
    <p>{`Ikonkort er kort med en gitt maksbredde og et venstrestilt ikon.
Den tilbyr alle underkomponenter (CardName, Title, Subtext, Text og CardAction) og plasserer dem i
gitt rekkefølge nedover i kortet, med teksten venstrestilt, til høyre for ikonet.`}</p>
    <DSExample name="cards_IconCard" mdxType="DSExample" />
    <UUTestReport reportID="Cards_IconCard" mdxType="UUTestReport" />
    <p>{`Kortet kan gjøres mer kompakt med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`condensed`}</code>{`-modifyeren.`}</p>
    <DSExample name="cards_IconCard_condensed" mdxType="DSExample" />
    <h3>{`Ikonbruk`}</h3>
    <p>{`Ikonene som brukes i kortene skal være ha weight `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`300`}</code>{`. Størrelsen er `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`lg`}</code>{` i `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`condensed`}</code>{`-versjonen og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`xl`}</code>{` i den normale versjonen.`}</p>
    <h2>{`IllustrationCard`}</h2>
    <p>{`Illustrasjonskort er kort med en venstrestilt illustrasjon.
Den tilbyr alle underkomponenter (CardName, Title, Subtext, Text og CardAction) og plasserer dem i
gitt rekkefølge på høyresiden nedover i kortet (med teksten venstrestilt)`}</p>
    <DSExample name="cards_IllustrationCard" mdxType="DSExample" />
    <UUTestReport reportID="Cards_IllustrationCard" mdxType="UUTestReport" />
    <p>{`Kortet kan gjøres mer kompakt med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`condensed`}</code>{`-modifyeren.`}</p>
    <DSExample name="cards_IllustrationCard_condensed" mdxType="DSExample" />
    <h2>{`GroupCard`}</h2>
    <p>{`GroupCard er et kort med en liste av elementer inni. Det kan bestå av `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`GroupCardTitle`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`GroupCardElement`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`GroupCardFooter`}</code>{`, men trenger ikke alle.`}</p>
    <DSExample name="cards_GroupCard" mdxType="DSExample" />
    <p>{`Man kan modifisere GroupCard til å ikke ha skygge med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`shadow={false}`}</code>{`.`}</p>
    <DSExample name="cards_GroupCard_noShadow" mdxType="DSExample" />
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`GroupCardElement`}</code>{` har linjer mellom elementene som standard, men det kan fjernes med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`noSeparator`}</code>{`.`}</p>
    <DSExample name="cards_GroupCard_noSeparator" mdxType="DSExample" />
    <p>{`Akkurat som med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`CardBase`}</code>{`, er ikke `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`GroupCardElement`}</code>{` klikkbart som standard. Bruk `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`CardAction`}</code>{` for å lage et klikkbart kort.`}</p>
    <DSExample name="cards_GroupCard_clickable" mdxType="DSExample" />
    <h2>{`StippledCard`}</h2>
    <p>{`StippledCard er kort med en stiplet kantlinje. Den tilbyr alle underkomponenter (CardName, Title, Subtext, Text og CardAction) og plasserer dem i
gitt rekkefølge nedover i kortet, med teksten venstrestilt. Kortet kan brukes i kombinasjon med ikoner eller illustrasjoner, eller uten noen av delene.`}</p>
    <DSExample name="cards_StippledCard" mdxType="DSExample" />
    <p>{`StippledCard kan gjøres mer kompakt med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`condensed`}</code>{`-modifyeren.`}</p>
    <DSExample name="cards_StippledCard_condensed" mdxType="DSExample" />
    <h2>{`ImageCard`}</h2>
    <p>{`Bildekort er kort med en gitt maksbredde, minimumshøyde, og et topplasert bilde.
Den tilbyr alle underkomponenter (CardName, Title, Subtext, Text og CardAction) og plasserer dem i
gitt rekkefølge nedover i kortet, med teksten midtstilt, nedenfor bildet.`}</p>
    <DSExample name="cards_ImageCard" mdxType="DSExample" />
    <DSExample name="cards_ImageCard_titleOnly" mdxType="DSExample" />
    <UUTestReport reportID="Cards_ImageCard" mdxType="UUTestReport" />
    <h2>{`Klikkbare kort`}</h2>
    <p>{`Kort er ikke klikkbare som standard. Bruk `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<CardAction/>`}</code>{` for å lage et klikkbart kort. En `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<CardAction/>`}</code>{` kan brukes som en lenke eller en knapp ved hjelp av `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`as`}</code>{`.`}</p>
    <DSExample name="cards_Cards_clickable" mdxType="DSExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      